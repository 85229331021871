import axios from 'axios'
import api from '@/api'

export const tasksModule = {
    namespaced: true,

    state: () => ({
        tasksList: null,
        tasksList_index: [],        
        users: null,
        users_modal_loading: true,
        user_modal_show: false,
        user_modal_type: null,
        users_selected: {
            'performers' : [],
            'viewers': []
        },
        searchQuery: '',

        departments: null,
        departments_modal_show: null,
        departments_modal_loading: true,
        departments_searchQuery: '',
        departments_selected: []
    }),

    mutations: {
        setTasks(state, data) {
            for(let i = 0; i < data.length; i++) {
                if (new Date(data[i].date) < new Date()) {
                    data[i].is_expired = true
                } else {
                    data[i].is_expired = false
                }
            }
            state.tasksList = data.sort((a, b) => new Date(b.date_to) - new Date(a.date_to))
        },

        setCurrentTask(state, data) {
            const task = data

            if(task) {
                if(task.departments.length != 0) {
                    for(let i = 0; i < task.departments.length; i++) {
                        task.departments[i].selected = true
                        state.departments_selected.push(task.departments[i])
                    }
                } 
                if(task.performers.length != 0) {
                    for(let i = 0; i < task.performers.length; i++) {
                        task.performers[i].selected = true
                        state.users_selected['performers'].push(task.performers[i])
                    }
                }    
                if(task.viewers.length != 0) {
                    for(let i = 0; i < task.viewers.length; i++) {
                        task.viewers[i].selected = true
                        state.users_selected['viewers'].push(task.viewers[i])
                    }
                } 
                if(task.files.length != 0) {
                    for(let i = 0; i < task.files.length; i++) {
                        let name = task.files[i].file.split('/')
                        name = name[name.length-1]
                        task.files[i].name = name
                    }
                }
            }

            state.current_task = task
        },

        setUsers(state, data) {
            for(let i = 0; i < data.length; i++) {
                const performers = state.users_selected.performers.find(x => x.id == data[i].id)
                const viewers = state.users_selected.viewers.find(x => x.id == data[i].id)
                
                if(performers || viewers) {
                    data[i].selected = true
                } else {
                    data[i].selected = false
                }
            }

            state.users = data
        },

        userModalActive(state, data) {
            if(data.bool) {
                state.user_modal_show = data.bool
                state.user_modal_type = data.type
            } else {
                state.user_modal_show = data.bool
                state.user_modal_type = null
            }
        },

        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery
        },

        userModalHander(state, data) {
            if(data.status == 'apply') {
                state.user_modal_show = false
                state.user_modal_type = null

                for(let i = 0; i < state.users.length; i++) {
                    state.users[i].selected = false
                }
            } else {
                state.user_modal_show = false,
                state.user_modal_type = null
                state.users_selected[data.type] = []

                for(let i = 0; i < state.users.length; i++) {
                    state.users[i].selected = false
                }
            }
        },

        clearSelectedUsers(state) {
            state.current_task = null
            state.users_selected = {
                'performers' : [],
                'viewers': []
            }
        },

        setDepartments(state, data) {
            for(let i = 0; i < data.length; i++) {
                const elem = state.departments_selected.find(x => x.id == data[i].id)
                if(elem) {
                    data[i].selected = true
                } else {
                    data[i].selected = false
                }
            }
            
            state.departments = data
        },
    
        departmentsModalActive(state, data) {
            if(data.bool) {
                state.departments_modal_show = data.bool
            } else {
                state.departments_modal_show = data.bool
            }
        },

        setDepartmentsSearchQuery(state, searchQuery) {
            state.departments_searchQuery = searchQuery
        },

        departmentsModalHander(state, data) {
            if(data.status == 'apply') {
                state.departments_modal_show = false

                for(let i = 0; i < state.departments.length; i++) {
                    state.departments[i].selected = false
                }
            } else {
                state.departments_modal_show = false,
                state.departments_selected = []

                for(let i = 0; i < state.departments.length; i++) {
                    state.departments[i].selected = false
                }
            }
        },

        clearSelectedDepartments(state) {
            state.current_task = null
            state.departments_selected = []
        },
    },
    
    getters: {
        getDashboardTasks(state) {
            if(state.tasksList) {
                const tasks = state.tasksList.sort((a, b) => new Date(a.date_to) - new Date(b.date_to)).slice(0, 5)
                return tasks.sort((a, b) => new Date(b.date_to) - new Date(a.date_to))
            }
        },

        getUsers(state) {
            return state.users.filter(user => user.first_name.toLowerCase().includes(state.searchQuery.toLowerCase()))
        }, 

        getDepartments(state) {
            return state.departments.filter(user => user.name.toLowerCase().includes(state.departments_searchQuery.toLowerCase()))
        }
    },

    actions: {
        async getTasksList({ commit, rootState }) {
            rootState.isLoading = true

            try {
                const response = await axios.get(api + "/tasks/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setTasks', response.data)
            } catch (err) { // Если есть ошибка
                console.log(err);
            }

            rootState.isLoading = false
        },

        async getCurrentTask({ commit }, id) {
            try {
                const response = await axios.get(`${api}/tasks/${id}/`, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                
                commit('setCurrentTask', response.data)
            } catch (err) { // Если есть ошибка
                console.log(err);
            }
        },

        async fetchUsers({ state, commit }) {
            state.users_modal_loading = true
            try {
                const response = await axios.get(api + "/users", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setUsers', response.data)
                state.users_modal_loading = false
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },

        async changeTaskStatus({ dispatch }, data) {
            const key = Object.keys(data)[0]

            switch(key) {
                case 'is_completed':
                    try {
                        const response = await axios.patch(api + "/tasks/" + data.id + '/', {
                            is_completed: data.is_completed
                        },
                            {
                                headers: {
                                    Authorization: 'Token ' + localStorage.getItem('token')
                                }
                            })
                        dispatch('getTasksList')
                    } catch (err) { // Если есть ошибка
                        alert(err)
                    }

                    break
                case 'is_active':
                    try {
                        const response = await axios.patch(api + "/tasks/" + data.id + '/', {
                            is_active: data.is_active, 
                            is_completed: false
                        },
                            {
                                headers: {
                                    Authorization: 'Token ' + localStorage.getItem('token')
                                }
                            })
                        dispatch('getTasksList')
                    } catch (err) { // Если есть ошибка
                        alert(err)
                    }

                    break
            }
        },

        async completeTask({ dispatch }, data) {
            if (data.status) {
                data.status = false
            } else {
                data.status = true
            }

            try {
                const response = await axios.patch(api + "/tasks/" + data.id + '/', {
                    is_completed: data.status
                },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                dispatch('getTasksList')
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },

        async createTask({ dispatch }, data) {
            if(typeof data.date_to !== 'string') {
                data.date_to = data.date_to.toLocaleDateString().split('.').reverse().join('-')
            }

            try {
                const response = await axios.post(api + "/tasks/", {
                    name: data.name,
                    description: data.description,
                    date_to: data.date_to,
                    is_priority: data.status.priority,
                    is_active: data.status.processed,
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                dispatch('getTasksList')
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },

        async editTask({ state, commit, dispatch }, data) {
            const departments = state.departments_selected.map(function (obj) {
                return obj.id
            })
            const performers = state.users_selected['performers'].map(function (obj) {
                return obj.id
            })
            const viewers = state.users_selected['viewers'].map(function (obj) {
                return obj.id
            })
            
            if(typeof data.date !== 'string') {
                data.date_to = data.date.toLocaleDateString().split('.').reverse().join('-')
            }

            try {
                const response = await axios.patch(api + "/tasks/" + data.id + '/',
                    {
                        name: data.name,
                        description: data.description,
                        is_active: data.is_active,
                        is_priority: data.is_priority,
                        date_to: data.date_to,
                        departments: departments,
                        performers: performers,
                        viewers: viewers
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                dispatch('getTasksList')
                commit('clearSelectedUsers')
            } catch (err) { // Если есть ошибка
                console.log(err);
            }
        },

        async deleteTask({ dispatch }, id) {
            try {
                const response = await axios.delete(api + "/tasks/" + id + '/',
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })

                dispatch('getTasksList')
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },

        async fetchDepartments({ state, commit }) {
            state.departments_modal_loading = true
            try {
                const response = await axios.get(api + "/departments/",
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })

                commit('setDepartments', response.data)
                state.departments_modal_loading = false
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },

        async addTaskFile({ state, dispatch }, data) {
            if(data.files.length > 1) {
                for(let i = 0; i < data.files.length; i++) {
                    try {
                        const response = await axios.post(api + "/tasks/" + data.id + '/files/',
                            data.files[i],
                            {
                                headers: {
                                    Authorization: 'Token ' + localStorage.getItem('token')
                                }
                            }) 

                            console.log(response)
                    } catch (err) { // Если есть ошибка
                        alert(err)
                    }
                }
            } else {
                try {
                    const response = await axios.post(api + "/tasks/" + data.id + '/files/',
                        data.files[0],
                        {
                            headers: {
                                Authorization: 'Token ' + localStorage.getItem('token')
                            }
                        }) 

                        console.log(response)
                } catch (err) { // Если есть ошибка
                    alert(err)
                }
            }

            dispatch('getTasksList')
        },

        async fileDelete({ commit, dispatch }, data) {
            try {
                const response = await axios.delete(`${api}/tasks/${data.task_id}/files/${data.file_id}`,
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })

                dispatch('getTasksList')
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}