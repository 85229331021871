import axios from 'axios'
import api from '@/api'

export const TimeManagementModule = {
    namespaced: true,

    state:() => ({
        isLoading: true,
        employees: null,
        employeeData: null,
        stations: null,
    }),

    mutations: {
        setEmployees(state, data) {
            state.employees = data
        },

        setEmployeeData(state, data) {
        
            for(let i = 0; i < data.shift_opportunities.length; i++) {
                if(data.shift_opportunities[i].start_time) {
                    data.shift_opportunities[i].start_time = data.shift_opportunities[i].start_time.substring(0, 5)
                }
                if(data.shift_opportunities[i].end_time) {
                    data.shift_opportunities[i].end_time = data.shift_opportunities[i].end_time.substring(0, 5)
                }
                if(data.shift_opportunities[i].unavailable_start_time) {
                    data.shift_opportunities[i].unavailable_start_time = data.shift_opportunities[i].unavailable_start_time.substring(0, 5)
                }
                if(data.shift_opportunities[i].unavailable_end_time) {
                    data.shift_opportunities[i].unavailable_end_time = data.shift_opportunities[i].unavailable_end_time.substring(0, 5)
                }
            }
            console.log(data)
            state.employeeData = data
        },

        setStations(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].value = data[i].id
                data[i].label = data[i].name
                data[i].checked = false
            }
            
            state.stations = data
        },
    },

    actions: {
        getEmployees({ state, commit }, data) {
            state.isLoading = true
            axios
            .get(api + "/" + data + "/shift-employees/",
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setEmployees', response.data)
                state.isLoading = false

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getEmployeeData({ state, commit }, data) {
            state.isLoading = true

            axios
            .get(api + "/" + data.shop + "/shift-employees/" + data.id,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setEmployeeData', response.data)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        fetchStations({ commit }) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/stations/`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setStations', response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        saveEmployeeChanges({ dispatch }, data) {
            let employee = null

            for(let i = 0; i < data.days.length; i++) {
                if(!employee) {
                    employee = data.days[i].employee
                }

                axios
                .patch(api + "/" + data.shop + "/shift-opportunities/" + data.days[i].id + '/', 
                data.days[i],
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    
                })
                .then(() => {
                })
                .catch((error) => {
                    alert(error)
                });
            }

            dispatch('getEmployeeData', { shop: data.shop , id: employee })
        },

        sendRemoveEmployee({ dispatch }, data) {
            console.log(data)
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.employee + '/', {
                is_active: false
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getEmployees', data.shop)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        sendAddEmployee({ dispatch }, data) {
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.employee + '/?all=true', {
                is_active: true
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getEmployees', data.shop)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        changeEmployeePhone({}, data) {
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.employee.id + '/?all=true', {
                phone: data.employee.phone
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        employeeDeactivate({}, data) {
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.id + '/?all=true', {
                user_delete: true
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        changeEmployeeStation({ dispatch }, data) {
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.employee_id + '/', {
                stations: data.stations
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.status == 200) {
                    dispatch('getEmployeeData', { shop: data.shop, id: data.employee_id })
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}