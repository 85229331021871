import axios from 'axios'

export const shiftPlaningModule = {
    namespaced: true,

    state:() => ({
        isLoading: true,
        shiftRoles: null,
        sections: null,
        stations: null,
        shiftPlanings: null,
        defaultSection: null,
    }),

    mutations: {
        setShiftRoles(state, data) {
            state.shiftRoles = data
        },

        setSections(state, data) {
            if(data.length !== 0) {
                state.sections = data
            } else {
                state.sections = null
            }
        },

        setStations(state, data) {
            state.stations = data
        },

        setShiftPlanings(state, data) {
            if(data.length !== 0) {
                state.shiftPlanings = data
                state.defaultSection = data[0].id
                for(let section in data) {
                    if(data[section].roles.length != 0) {
                        for(let i = 0; i < data[section].roles.length; i++) {
                            data[section].roles[i].time_from ? data[section].roles[i].time_from = data[section].roles[i].time_from.substring(0, 5) : null
                            data[section].roles[i].time_to ? data[section].roles[i].time_to = data[section].roles[i].time_to.substring(0, 5) : null
                        }
                    }
                }
            } else {
                state.shiftPlanings = null
                state.defaultSection = null
            }
        }
    },

    actions: {
        fetchSections({ commit }, shop) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/${shop}/sections`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setSections', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        fetchStations({ commit }) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/stations/`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setStations', response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        fetchShiftPlanings({ state, commit }, shop) {
            state.isLoading = true
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/${shop}/shift-plannings`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setShiftPlanings', response.data)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        createNewSection({ dispatch }, data) {
            axios
            .post(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-plannings/`,
            {
                shop: data.shop,
                revenue: data.revenue,
                efficiency: data.efficiency,
                copy_from: data.copy_from ? data.copy_from : null
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop_code)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        deleteSection({ dispatch }, data) {
            axios
            .delete(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-plannings/${data.id}/`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        addRow({ dispatch }, data) {
            axios
            .post(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-planning-roles/`,
            {
                description: data.description,
                section: data.section,
                hours: data.hours,
                shift_planning: data.shift_planning,
                station: data.station,
                time_from: data.time_from,
                time_to: data.time_to
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        editRow({ dispatch }, data) {

            axios
            .patch(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-planning-roles/${data.id}/`,
            {
                description: data.description,
                section: data.section,
                hours: data.hours,
                shift_planning: data.shift_planning,
                station: data.station,
                time_from: data.time_from,
                time_to: data.time_to
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        deleteRow({ dispatch }, data) {
            axios
            .delete(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-planning-roles/${data.id}/`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}