<template>
    <section class="section home">
        <div class="light">
            <img src="@/assets/img/decals/light.svg" alt="">
        </div>
        <div class="home-header">
            <div class="home-header-col">
                <!-- <h1 class="home__title" v-if="first_name !== ''">Добрый день, {{ first_name }}!</h1>
				<h1 class="home__title" v-else="first_name !== ''">Добрый день, {{ username }}!</h1> -->
                <p class="home__subtitle">Сегодня {{ current_day }}, {{ current_date }}</p>
            </div>
        </div>
        <div class="home-body">
            <div class="col-2" v-if="getUserPermissions && getUserPermissions.includes('goals_show')">
                <dashboardGoals :goalsCount="goalsCount" :goalsList="goalsList"/>
            </div>
            <div class="col-2">
                <dashboardMetriks :metriks="metriks"/>
            </div>
            <div class="col-2" v-if="getDashboardTasks">
                <dashboardTasks :tasks="getDashboardTasks" @changeTask="changeTaskStatus"/>
            </div>
            <div :class="getUserPermissions && getUserPermissions.includes('goals_show') ? 'col-2' : 'col-4'" >
                <dashboardReviews :class="getUserPermissions && getUserPermissions.includes('goals_show') ? 'sm' : ''"/>
            </div>
            <!-- <div class="col-4" v-if="users">
                <div class="window window-users window_full">
                    <div class="window-header">
                        <img src="@/assets/img/icons/icon-tasks.svg" alt="Иконка" class="window-header__icon" />
                        <p class="window-header__title">
                            Адресная книга
                        </p>

                        <input type="text" v-model="user_search" class="input window-users__search" placeholder="Поиск">
                        <span class="window-users__search_icon">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="#7992F9" stroke-width="1.1"/>
                                <path d="M16 16L13 13" stroke="#7992F9" stroke-width="1.1" stroke-linecap="round"/>
                            </svg>
                        </span>
                    </div>

                    <div class="window-users-table">
                        <div class="window-users-table-item window-users-table-item_header">
                            <div class="col">Сотрудник</div>
                            <div class="col">Должность</div>
                            <div class="col">Номера телефона</div>
                        </div>
                        <div
                            class="window-users-table-item"
                            v-for="user in users.filter(user => user.first_name.toLowerCase().includes(this.user_search.toLowerCase()))"
                            :key="user.id"
                        >
                            <router-link :to="`/users/${user.id}`" class="col link">{{ user.first_name }} {{ user.last_name }}</router-link>
                            <div class="col roles">
                                <p v-for="role in user.roles" :key="role.id">{{ role.name }}<span>,&nbsp;</span></p>
                            </div>
                            <div class="col"></div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dashboardGoals from "@/components/dashboard/dashboardGoals.vue"
import dashboardMetriks from "@/components/dashboard/dashboardMetriks.vue"
import dashboardTasks from "@/components/dashboard/dashboardTasks.vue"
import dashboardReviews from "@/components/dashboard/dashboardReviews.vue"

export default {
    name: "HomeView",

    data() {
        return {
            task_select: "неделя",
            current_date: "",
            current_day: "",
            current_time: "",
            user_search: ''
        };
    },

    components: {
        dashboardGoals, dashboardMetriks, dashboardTasks, dashboardReviews
    },

    computed: {
        ...mapState({
            tcoins: (state) => state.tcoins,
            goalsList: (state) => state.home.goalsList,
            goalsCount: (state) => state.home.goalsCount,
            metriks: (state) => state.home.metriks,
            notifications: (state) => state.home.notifications,
            users: (state) => state.home.users,
            user_permissions: (state) => state.user_permissions,
        }),

        ...mapGetters({
            getDashboardTasks: 'tasks/getDashboardTasks',
            getUserPermissions: 'getUserPermissions'
        })
    },

    methods: {
        ...mapActions({
            getGoalsList: "home/getGoalsList",
            getTasksList: "tasks/getTasksList",
            completeTask: "tasks/completeTask",
            getMetriks: "home/getMetriks",
            getShop: "home/getShop",
            getNotifications: "home/getNotifications",
            getUsers: "home/getUsers",
        }),

        changeTaskStatus(id, status) {
            let data = {
                status: status,
                id: id,
            };

            this.completeTask(data);
        },

        getCurrentDate() {
            let date = new Date();
            let monthNames = [
                "января",
                "февраля",
                "марта",
                "апреля",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря",
            ];
            let dayNames = ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"];
            let day = date.getUTCDate();
            let month = monthNames[date.getUTCMonth()];
            let year = date.getUTCFullYear();

            this.current_date = day + " " + month + " " + year;
            this.current_day = dayNames[new Date().getDay()];
        },
    },

    mounted() {
        this.getMetriks()
        this.getGoalsList()
        this.getTasksList()
        this.getCurrentDate()
        this.getNotifications()
        // this.getUsers()
    },
};
</script>
<style scoped lang="sass">
.col-4
    grid-column: 1 / -1

.window-users
    & .window-header
        position: relative
    &__search
        margin-left: auto
        width: 200px
        height: 28px
        border: none
        &_icon
            position: absolute
            top: 50%
            right: 26px
            transform: translateY(-40%)
    &-table
        display: flex
        flex-direction: column
        align-items: flex-start
        &-item
            display: grid
            grid-template-columns: 50% 25% 25%
            align-items: center
            width: 100%
            &_header
                color: #808F9F
            &:nth-child(even)
                background: #FAFAFA
            & .col
                padding: 0 18px
                display: flex
                align-items: center
                height: 50px
                overflow-x: hidden 
                &:not(:last-child)
                    border-right: 1px dotted #D8D8D8
                &.link
                    font-weight: 600
                    color: var(--color-blue)
                &.roles
                    & p
                        white-space: nowrap
                        &:last-child span
                            display: none

.light
    position: absolute
    top: -80px
    right: 100px
    display: flex
    @media only screen and (max-width: 1024px)
        top: -60px
        right: -200px
    @media only screen and (max-width: 425px)
        top: -55px
        right: -400px
    & img
        @media only screen and (max-width: 1024px)
            width: 80%
        @media only screen and (max-width: 425px)
            width: 70%
</style>
