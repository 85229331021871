import axios from 'axios'

export const profitsModule = {
    namespaced: true,

    state:() => ({
        profitsLoading: false,
        profitsData: {},
        filterDate: null
    }),

    mutations: {
        setProfitsData(state, data) {
            state.profitsData = data
            state.profitsData.total = Math.round(((state.profitsData.month_total / 100) * 13 + state.profitsData.month_total))
            state.profitsLoading = false
        }
    },

    actions: {
        getProfitsData({ state, commit }, date) {
            state.profitsLoading = true

            let format_date = [
                date['year'],
                date['month'].length === 1 ? date['month'] = '0' + date['month'] : date['month']
            ]

            format_date = format_date.join('-')

            axios
            .get(`https://admin.tcode.online/api/projects/tcode/earning/` + format_date, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
                commit('setProfitsData', response.data)
            })
            .catch((err) => {
                console.log(err);
            })
        },
    }
}