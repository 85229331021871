import axios from 'axios'
import router from '@/router';

export const ordersListModule = {
    namespaced: true,

    state:() => ({
        shops: null,
        orders: null,
        suppliers: null,
        currentOrder: null,
        searchQuery: '',
        products: null,
        pages: null,
    }),

    mutations: {
        setShops(state, data) {
            const shop = router.currentRoute.value.query.shop

            for(let i = 0; i < data.length; i++) {
                if(Array.isArray(shop)) {
                    const elem = shop.find(item => item == data[i].id)

                    if(data[i].id == elem) {
                        data[i].checked = true
                    } else {
                        data[i].checked = false
                    }
                } else {
                    if(data[i].id == shop) {
                        data[i].checked = true
                    } else {
                        data[i].checked = false
                    }
                }
            }
            state.shops = data
        },

        setOrders(state, data) {
            state.orders = data
        },

        setSuppliers(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].label = data[i].name
                data[i].value = data[i].id
                const elem = state.orders.find(x => x.supplier.id == data[i].id)
                elem ? data[i].checked = true : data[i].checked = false
            }

            state.suppliers = data
        },

        setCurrentOrder(state, data) {
            data.delivery_datetime_to = data.delivery_datetime_to.split('T')[0]
            for(let i = 0; i < data.items.length; i++) {
                data.items[i].count_edited = false
                data.items[i].delete_edited = false
                data.items[i].price_edited = false
            }
            console.log(data);
            state.currentOrder = data
        },

        setProducts(state, data) {
            state.products = data
        },

        setSearchQuery(state, searchQuery) {
            console.log(searchQuery)
            state.searchQuery = searchQuery
        },

        setPages(state, data) {
            state.pages = data
        },
    },

    getters: {
        getProducts(state) {
            if(state.products) {
                return state.products.filter(product => product.name.toLowerCase().includes(state.searchQuery.toLowerCase()))
            }
        }
    },

    actions: {
        getShops({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tcode/shops/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < response.data.length; i++) {
                    response.data[i].label = response.data[i].name
                    response.data[i].value = response.data[i].id
                }

                commit('setShops', response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getSuppliers({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/suppliers/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setSuppliers', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getOrders({ rootState, commit, dispatch }) {
            rootState.isLoading = true
            const shops = router.currentRoute.value.query.shop

            if(shops) {
                axios
                .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/orders?" + router.currentRoute.value.fullPath.split('?')[1], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('setOrders', response.data.results)
                    commit('setPages', response.data.num_pages)
                    dispatch('getSuppliers')
                    rootState.isLoading = false
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },

        getCurrentOrder({ commit }, id) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/orders/" + id, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setCurrentOrder', response.data)
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },

        changeOrderStatus({ state, dispatch }, data) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.id +"/?status=" + data.status, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.success) {
                    dispatch('getOrders')
                    state.currentOrder = null
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        sendInvoice({ dispatch }, data) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/${data.id}?${data.type}=create`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getOrders')
            })
            .catch((error) => {
                console.log(error);
            });
        },

        orderDelete({ state, dispatch }, id) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ id +"/?order_del=true", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.success) {
                    state.currentOrder = null
                    dispatch('getOrders')
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        inputProductCount({ state, dispatch }, data) {
            if(data.type === 'plus') {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    if(state.currentOrder.items[i].id === data.id) {
                        state.currentOrder.items[i].quantity = state.currentOrder.items[i].quantity + Number(state.currentOrder.items[i].product.multiplicity)
                        state.currentOrder.items[i].sum_float = state.currentOrder.items[i].price_float * state.currentOrder.items[i].quantity
                        state.currentOrder.items[i].count_edited = true
                    }
                }
            } else if (data.type === 'minus') {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    if(state.currentOrder.items[i].id === data.id && state.currentOrder.items[i].quantity > 1) {
                        state.currentOrder.items[i].quantity = state.currentOrder.items[i].quantity - Number(state.currentOrder.items[i].product.multiplicity)
                        state.currentOrder.items[i].sum_float = state.currentOrder.items[i].price_float * state.currentOrder.items[i].quantity
                        state.currentOrder.items[i].count_edited = true
                    }
                }
            }
        },

        inputProductPrice({ state }, data) {
            const new_price = data.event.target.value
            if(data.old_price != new_price) {
                const item = state.currentOrder.items.find(x => x.id == data.id)
                item.product.price_float = Number(new_price)
                item.price = Number(new_price)
                item.price_edited = true
            }
        },

        inputProductDelete({ state }, id) {
            for(let i = 0; i < state.currentOrder.items.length; i++) {
                if(state.currentOrder.items[i].id === id) {
                    state.currentOrder.items[i].delete_edited = true
                    if(state.currentOrder.items[i].is_cancelled) {
                        state.currentOrder.items[i].is_cancelled = false
                    } else {
                        state.currentOrder.items[i].is_cancelled = true
                    }
                }
            }
        },

        changeOrderProductCount({ state }, data) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?item_edit=" + data.item_id +"&item_quantity=" + data.item_count, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    if(state.currentOrder.items[i].id === data.item_id) {
                        state.currentOrder.items[i].count_edited = false
                    }
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        orderProductDelete({ dispatch, state }, data) {
            if(data.deleted) {
                axios
                .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?item_del=" + data.item_id, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    for(let i = 0; i < state.currentOrder.items.length; i++) {
                        if(state.currentOrder.items[i].id === data.item_id) {
                            state.currentOrder.items[i].delete_edited = false
                        }
                    }
                    if(response.data.success) {
                        dispatch('getCurrentOrder', response.data.order.id)
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });
            } else {
                axios
                .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?item_return=" + data.item_id, {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    for(let i = 0; i < state.currentOrder.items.length; i++) {
                        if(state.currentOrder.items[i].id === data.item_id) {
                            state.currentOrder.items[i].delete_edited = false
                        }
                    }
                    if(response.data.success) {
                        dispatch('getCurrentOrder', response.data.order.id)
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });
            }   
        },

        changeOrderProductPrice({}, data) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?item_edit=" + data.item_id +"&item_price=" + data.item_price, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < state.currentOrder.items.length; i++) {
                    const item = state.currentOrder.items.find(x => x.id == data.item_id)
                    item.price_edited = false
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        orderDownload({}, id) {
            axios
                .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ id +"/?export=xlsx", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    const url = URL.createObjectURL(response.data)
                    const a = document.createElement('a')
                    a.href = url
                    a.download = `Заказ_${id}.xls`
                    a.style.display = 'none'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                    URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },

        changeOrderComment({}, data) {
            console.log(data.comment);
            axios
            .post("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.id + "/", 
            {
                comment: data.comment
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        changeOrderDate({ dispatch }, data) {
            let date = data.delivery_datetime_to
            if(typeof date !== 'string') {
                date = date.toLocaleDateString().split('.').reverse().join('-')
            }

            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.id + "?datesave=" + date,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getOrders')
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        fetchProducts({ commit }, supplier) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay-production/shops/production/products/?supplier=${supplier}&ordering=name`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
                commit('setProducts', response.data.results)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        addNewProduct({ dispatch }, data) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/${data.order}?product_add=${data.product}`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.success) {
                    dispatch('getCurrentOrder', response.data.order.id)
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}